import React from "react";
import "./index.css";
import Navigation from "../components/Navigation/Navigation";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Copyright from "../components/copyright/Copyright";

const PrivacyPolicy = () => {
  const socials = [
    {
      icon: "instagram-alt",
      to: "https://www.instagram.com/biz_mkononi",
    },
    {
      icon: "facebook",
      to: "https://www.facebook.com/MKONONIBIZKENYA",
    },
    {
      icon: "twitter",
      to: "https://twitter.com/BizMkononi",
    },
  ];
  return (
    <>
      <div className="navigation-2">
        <Link to="/" className="company-logo">
          <img src="https://res.cloudinary.com/emacon-production/image/upload/v1638545506/Bizmkononi/BIZ_MKONONI_png_couxsj.png" />
        </Link>

        <div className="">
          <Navigation />
        </div>
      </div>
      <div className="privacy-policy">
        <h1>Lloyd Constellations Privacy Policies</h1>
        <section>
          <h2>Privacy Policy</h2>
          <p>
            Biz Mkononi is committed to protecting your privacy. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website
            <a href="http://www.bizmkononi.com"> www.bizmkononi.com</a> and use
            our services, including our AI-powered insights platform,
            decision-making tools, solutions, and analytics (collectively, the
            "Services").
          </p>
          <p>
            Please read this privacy policy carefully. If you do not agree with
            the terms of this privacy policy, please do not access the site or
            use our services.
          </p>
        </section>
        <section>
          <h2>Collection of Information</h2>
          <p>We may collect and process the following data about you:</p>
          <ul>
            <li>
              Personal Identification Information: Name, email address, phone
              number, company name, and any other information you provide
              voluntarily.
            </li>
            <li>
              Non-Personal Identification Information: Browser type, IP address,
              operating system, and other technical details collected through
              cookies and similar technologies.
            </li>
          </ul>
        </section>
        <section>
          <h2>Use of Information</h2>
          <p>We use the information we collect in the following ways:</p>
          <ul>
            <li>To provide, operate, and maintain our services.</li>
            <li>To improve, personalize, and expand our services.</li>
            <li>
              To understand and analyze how you use our services and develop new
              products, services, features, and functionality.
            </li>
            <li>
              To communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the service, and for
              marketing and promotional purposes.
            </li>
            <li>To process your transactions and manage your orders.</li>
          </ul>
        </section>
        <section>
          <h2>Disclosure of Information</h2>
          <p>
            We do not sell, trade, or otherwise transfer to outside parties your
            personally identifiable information. This does not include trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, so long as those parties agree to keep
            this information confidential.
          </p>
        </section>
        <section>
          <h2>Data Security</h2>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit, or access your personal information.
          </p>
        </section>
        <section>
          <h2>Your Rights</h2>
          <p>
            You have the right to access personal information we hold about you
            and to ask that your personal information be corrected, updated, or
            deleted. If you would like to exercise this right, please contact us
            through the contact information provided below.
          </p>
        </section>
        <section>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            Biz Mkononi may update this Privacy Policy from time to time. We
            will notify you of any changes by posting the new Privacy Policy on
            this page. You are advised to review this Privacy Policy
            periodically for any changes.
          </p>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <address>
            Biz Mkononi
            <br />
            P.O BOX 1242 - 00100
            <br />
            Nairobi, Kenya
            <br />
            <a href="mailto:info@lloydconstellations.co.ke">
              info@lloydconstellations.co.ke
            </a>
            <br />
            +254725211846
          </address>
        </section>
      </div>
      <div className="section-8">
        <Row>
          <Col md={3} className="footer">
            <h6>CONTACT US:</h6>
            <div>
              <p>+254 701 063018</p>
              <p>hello@mkononi.biz</p>
              <p>Nairobi, Kenya</p>
            </div>
          </Col>
          <Col md={3} className="footer">
            <h6>SERVICES:</h6>
            <div>
              <p>Contact Us</p>
              <p>Ordering & Payment</p>
              <p>FAQs</p>
            </div>
          </Col>
          <Col md={3} className="footer">
            <h6>INFORMATION:</h6>
            <div className="links-footer">
              <a href="/">Work With US</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/">Terms & Conditions</a>
              <a href="/">Press Enquiries</a>
            </div>
          </Col>
          <Col md={3} className="socials-footer">
            {socials.map((item, index) => (
              <div key={index} className="socials">
                <a href={item.to} target="_blank">
                  <i class={`bx bxl-${item.icon}`}></i>
                </a>
              </div>
            ))}
          </Col>
        </Row>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
