import React from "react";
import { Link } from "react-router-dom";
import "./button.css";

function Button(props) {
  return (
    <React.Fragment>
      {props.target === undefined ? (
        <a
          onClick={props.handleFunction}
          target={props.newPage ? "_blank" : "_self"}
          href="https://app.mkononi.biz/#/auth/login"
          className={`${props.style === "main" ? "main" : "transparent"} ${
            props.marginTop === "1" ? "top-one" : ""
          }`}
        >
          <button>{props.content}</button>
        </a>
      ) : (
        <Link
          to={`${props.target}`}
          target={props.newPage ? "_blank" : "_self"}
          className={`${props.style === "main" ? "main" : "transparent"} ${
            props.marginTop === "1" ? "top-one" : ""
          }`}
          onClick={props.handleFunction}
        >
          <button>{props.content}</button>
        </Link>
      )}
    </React.Fragment>
  );
}

export default Button;
