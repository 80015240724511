import React from 'react'
import './card.css'

function Card(props) { 
    return (
        <div className={`${props.type === 'priceList' ? 'priceList' : 'card'} ${props.color === 'white' ? 'white' : 'blue'} ${props.borderRadius === 'topCorner' ? 'topCorner' : 'normalCorner'} ${props.index === 0 ? "zero" : ''} ${props.index === 1 ? "one" : ''} ${props.index === 2 ? "two" : ''} ${props.index === 3 ? "three" : ''} ${props.index === 4 ? "four" : ''}`}>
            {
                props.renderBody
            }
        </div>
    )
}

export default Card
