import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Google analytics =============

export const GApageView = () => {
  window.gtag("config", "UA-221749741-1");
};

export const GAConversionEvent = (e) => {
  e.preventDefault();
  window.gtag("event", "conversion", {
    send_to: "UA-221749741-1/vOtGCJr836YDEPm487cC",
  });
  window.open("https://app.mkononi.biz");
};

// Google analytics =============

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
