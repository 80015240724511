import React, { useState, useEffect } from "react";
import "./navigation.css";
import { Link as ScrollLink } from "react-scroll";
import Button from "../button/Button";
import { Link, useLocation } from "react-router-dom";
import { GAConversionEvent, GApageView } from "../../";

const navigationData = [
  {
    name: "HOME",
    to: "/",
  },
  {
    name: "FEATURES",
    to: { pathname: "/learn-more" },
  },
  {
    name: "PRICING",
    to: { pathname: "/", hash: "#section-5" },
  },
  {
    name: "CONTACT",
    to: { pathname: "/", hash: "#section-8" },
  },
];

function Navigation() {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hash) {
      let elem = document
        .getElementsByClassName(window.location.hash.slice(1))
        .item(0);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="navWrapper">
      <div className="nav">
        <div className="navMenu">
          {navigationData.map((item, index) => (
            <React.Fragment>
              {/* {item.to === "/" ? (
                <Link
                  key={index}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  style={{ textDecoration: "none" }}
                >
                  <div className="navMenuLink">{item.name}</div>
                </Link>
              ) : (
                <ScrollLink
                  key={index}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  style={{ textDecoration: "none" }}
                >
                  <div className="navMenuLink">{item.name}</div>
                </ScrollLink>
              )} */}
              <Link
                key={index}
                to={item.to}
                spy={true}
                smooth={true}
                style={{ textDecoration: "none" }}
              >
                <div className="navMenuLink">{item.name}</div>
              </Link>
            </React.Fragment>
          ))}

          <Button
            handleFunction={(e) => GAConversionEvent(e)}
            newPage={true}
            to="https://app.mkononi.biz/#/auth/login"
            content="SIGN UP"
            style="transparent"
            marginTop="1"
          />
        </div>
      </div>
    </div>
  );
}

export default Navigation;
