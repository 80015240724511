import React from 'react'
import './copyright.css'

function Copyright() {
    return (
        <React.Fragment className="copyright">
            {'Copyright © '}
            <a color="inherit" href="">
                Biz Mkononi
            </a>{' '}
            {new Date().getFullYear()}
            
        </React.Fragment>
        
    )
}

export default Copyright