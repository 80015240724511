import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Carousel, ScrollingCarousel } from "@trendyol-js/react-carousel";
import Button from "../components/button/Button";
import Navigation from "../components/Navigation/Navigation";
import Card from "../components/card/Card";
import Copyright from "../components/copyright/Copyright";
import { Link } from "react-router-dom";
import { GAConversionEvent, GApageView } from "..";

const socials = [
  {
    icon: "instagram-alt",
    to: "https://www.instagram.com/biz_mkononi",
  },
  {
    icon: "facebook",
    to: "https://www.facebook.com/MKONONIBIZKENYA",
  },
  {
    icon: "twitter",
    to: "https://twitter.com/BizMkononi",
  },
];

const uniqunessData = [
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_109_nxxzqf.svg",
    title: "Business Intelligence",
    Details:
      "Biz Mkononi uses Customer data, transactional data and satellite data to give you a full-circle Models, projections and Insights are accurate so that a business owner is empowered to make the best decisions for their enterprise.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_109_nxxzqf.svg",
    title: "Big Data Analytics",
    Details:
      "With the dearth of Data not only in Africa but also globally, Biz Mkononi is integrating potentially over 10,000 data sets and processing north of 100 trillion data points in weeks so as to offer the best insights to our customers. Secondly, the data is visualized in the best way possible so that through our dashboard our clients can visualize their data using the best practices.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569260/Bizmkononi/Group_110_sgnfpg.svg",
    title: "360° Customer view & Churn Risk Analysis",
    Details:
      "Classify your customers, avoid customer churn rate while improving the return rate, upsell, resell and have a holistic view of your customers.",
  },
];

const featuresData = [
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Fill_out_line_wikaq6.svg",
    title: "Data Actions and Decision Modelling",
    description:
      "Biz Mkononi helps you see the Big Picture of the enterprise and at the same time helping you to act on the Small Details in real time helping you to pinpoint areas of concern by unlocking data driven insights and Business Intelligence helping your organization to become profitable.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569264/Bizmkononi/sales_icon_ozqt3u.svg",
    title: "Automated Business Management on Cloud",
    description:
      "This involves adding sales, deleting and updating sales. Sales module provides a receipt for the sale made that can be shared digitally.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569261/Bizmkononi/management_j4a9e6.svg",
    title: "Helping Businesses to Thrive",
    description:
      "Once a business insights are known and areas of concerns noted, Biz mkononi will match the business with a mentor or a solution provider to help the business overcome the highlighted issues.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569263/Bizmkononi/profit_1_tfrn3e.svg",
    title: "Profit Projections",
    description:
      "This helps in tracking the business profit in daily, weekly, monthly and yearly basis. The profit is displayed in the dashboard for quick access and by use of a user pin.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/expenses_h4qtsv.svg",
    title: "Sales Management",
    description:
      "This involves tracking of business expenses, whether a sale expense, stock expenses, bus fare, meals, salaries etc.",
  },
];

const packageFeatures = [
  "Automated Business management",
  "Business Intelligence",
  "Big data Analysis",
  "360- degree customer analysis",
  "Data Actions and Decision Modelling",
  "Churn Risk analysis",
  "Customer repeat rate",
  "3 Sources of Data",
];

const renderUniqueData = (image, title, details) => (
  <React.Fragment>
    <div className="card-img">
      <img src={image} alt="" />
    </div>
    <div className="card-title">
      <h3>{title}</h3>
    </div>

    <div className="card-description">
      <p>{details}</p>
    </div>
  </React.Fragment>
);

const renderFeaturesData = (image, title, details) => (
  <React.Fragment>
    <div className="card-img">
      <img src={image} alt="" />
    </div>
    <div className="card-title">
      <h3 style={{ textAlign: "center" }}>{title}</h3>
    </div>

    <div className="card-description">
      <p>{details}</p>
    </div>
  </React.Fragment>
);

const renderPriceList = (features, packagePrice) => (
  <React.Fragment>
    <h3>{packagePrice}</h3>
    {features.map((item, index) => (
      <div key={index} className="package-details">
        <p>{item}</p>
        <i class="bx bx-check"></i>
      </div>
    ))}
    <div className="trial-btn">
      <Button
        handleFunction={(e) => GAConversionEvent(e)}
        newPage={true}
        content="Start 7 Days Trial"
      />
    </div>
  </React.Fragment>
);

const renderOurTeam = (member) => (
  <div className="member">
    <div className="member_img">
      <img src={member.image} />
    </div>
    <div className="member_details">
      <h4>{member.name}</h4>
      <p>{member.role}</p>
    </div>
  </div>
);

const teamData = [
  {
    name: "Peter Njeri",
    role: "FOUNDER & CEO",
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1643275127/Bizmkononi/RAEng_LIF05_03_0548_1_zqoxce.jpg",
  },
  {
    name: "Kevin Chege",
    role: "CO-FOUNDER & CTO",
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1643275167/Bizmkononi/Llyod-42_khnwso.jpg",
  },
  {
    name: "Martin Kibanyu",
    role: "CO-FOUNDER & FRONT END DEVELOPER",
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1643275123/Bizmkononi/kibanyu_jvzm7c.jpg",
  },
  {
    name: "Suweys",
    role: "UI/UX LEAD",
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1643275166/Bizmkononi/SUWEYS_ABDULLAHI_vzc3qt.jpg",
  },
];

function LandingPage() {
  const [monthlyButtonType, setMonthlyButtonType] = useState("main");
  const [annualButtonType, setAnnualButtonType] = useState("transparent");
  const [packagePrice, setPackagePrice] = useState("Ksh 199.00");

  const changeAnnualStyle = () => {
    setMonthlyButtonType("transparent");
    setAnnualButtonType("main");
    setPackagePrice("Ksh 2,388.00");
  };

  const changeMonthlyStyle = () => {
    setMonthlyButtonType("main");
    setAnnualButtonType("transparent");
    setPackagePrice("Ksh 199.00");
  };

  useEffect(() => {
    GApageView("landing");
  }, []);

  return (
    <div className="App">
      <div className="section-1" id="section-1">
        <div className="navigation">
          <Link to="/" className="company-logo">
            <img src="https://res.cloudinary.com/emacon-production/image/upload/v1638545506/Bizmkononi/BIZ_MKONONI_png_couxsj.png" />
          </Link>

          <div className="">
            <Navigation />
          </div>
        </div>

        <Row className="hero">
          <Col md={1} sm={1}>
            {/* Socials */}
            {socials.map((item, index) => (
              <div key={index} className="socials">
                <a href={item.to} target="_blank">
                  <i class={`bx bxl-${item.icon}`}></i>
                </a>
              </div>
            ))}
          </Col>
          <Col md={10} sm={2} className="details">
            <h1>
              Manage and grow your <br /> business with us.
            </h1>
            <p>
              Biz Mkononi is an AI-powered insights platform that provides
              decision making tools, <br />
              solutions and analytics to the small and medium enterprises in
              Kenya and the Rest <br />
              of the Continent.
            </p>
            <Button
              handleFunction={(e) => GAConversionEvent(e)}
              newPage={true}
              content="Get Started"
              style="main"
            />
          </Col>
        </Row>

        <img
          src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
          alt=""
          className="sub-image"
        />
      </div>

      <div className="section-2">
        <h2>Why Choose Us?</h2>
        <p>
          The Biz mkononi platform aggregates, normalizes and models complex
          data to illuminate <br /> the ways which small and medium enterprises
          in Africa can be more resilient and robust through harnessing the
          power of technology.
        </p>
        <div className="uniquness-cards">
          <Row>
            {uniqunessData.map((item, index) => (
              <Col md={4} key={index}>
                <Card
                  renderBody={renderUniqueData(
                    item.image,
                    item.title,
                    item.Details
                  )}
                  color="white"
                  borderRadius="topCorner"
                />
              </Col>
            ))}
          </Row>
        </div>

        <img
          src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Ellipse_15_vht834.svg"
          alt=""
          className="sub-image-2"
        />
      </div>

      <div className="section-3">
        <div className="feature-basket">
          <img
            src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
            alt=""
            className="sub-image-3"
          />
          <h2>Our Feature Basket</h2>
        </div>

        <div className="feature-basket">
          <p>
            Biz Mkononi is an AI-powered insights platform that provides <br />
            decision making tools, solutions and analytics to the small and{" "}
            <br />
            medium enterprises in Kenya and the Rest of the Continent.
          </p>
        </div>
        <div className="feature-basket-btn">
          <Button
            newPage={true}
            target="/learn-more"
            content="Learn More"
            style="main"
          />
        </div>
        <div className="feature-basket">
          <img
            src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
            alt=""
          />
        </div>
      </div>

      <div className="section-4">
        <Row>
          <Col md={9} sm={12}>
            <Row>
              {featuresData.map((item, index) => (
                <Col md={4} sm={12} key={index} className="features-cards">
                  <Card
                    renderBody={renderFeaturesData(
                      item.image,
                      item.title,
                      item.description
                    )}
                    index={index}
                    color="white"
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={2} sm={12} className="wrapper">
            <img
              src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Ellipse_40_cxyxcb.svg"
              alt=""
              className="sub-image-5"
            />
          </Col>
        </Row>
      </div>

      <div className="section-5">
        <h2>Pricing</h2>
        <div className="time-selector">
          <h6>Comes In a Monthly Package</h6>
          {/* <div className="time-selector-btn">
                        <Button
                        content="MONTHLY"
                        style={monthlyButtonType}
                        handleFunction={changeMonthlyStyle}
                        />
                        <Button
                        content="ANNUAL"
                        style={annualButtonType}
                        handleFunction={changeAnnualStyle}
                        />
                    </div> */}
          {/* <h6>Package</h6> */}
        </div>
        <div className="price-list">
          <Card
            color="blue"
            type="priceList"
            renderBody={renderPriceList(packageFeatures, packagePrice)}
          />
        </div>
      </div>

      <div className="section-6">
        <Row className="feature">
          <Col md={6} className="feature-img">
            <img
              src="https://res.cloudinary.com/emacon-production/image/upload/v1637569260/Bizmkononi/Group_106_fvvply.svg"
              alt=""
            />
          </Col>
          <Col md={6}>
            <div className="feature-basket">
              <img
                src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
                alt=""
                className="sub-image-3"
              />
              <h2>Grow your Business with us.</h2>
            </div>

            <div className="feature-basket">
              <p>
                Biz Mkononi helps you See the Big Picture of the enterprise and
                at the same time helping you to Act on the Small Details in real
                time helping you to pinpoint areas of concern by unlocking data
                driven insights and Business Intelligence helping your
                organization to become profitable.
              </p>
            </div>
            <div className="feature-basket-btn">
              <Button
                handleFunction={(e) => GAConversionEvent(e)}
                newPage={true}
                content="SIGN UP NOW"
                style="main"
              />
            </div>
            <div className="feature-basket-img">
              <img
                src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="section-7">
        <Row className="">
          <Col md={6}>
            <div className="feature-basket">
              <img
                src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
                alt=""
                className="sub-image-3"
              />
              <h2>We Use your Data to make your Business Better.</h2>
            </div>

            <div className="feature-basket">
              <p>
                <p>
                  We use Customer data, transactional data, satellite and GIS
                  data and seek their interrelationship to help you get better
                  business insights to scale your enterprise.
                </p>
                <p>
                  <h4>Our Data Sources</h4>
                  <ul>
                    <li>Customer Data</li>
                    <li>Transactional Data</li>
                    <li>Satellite &amp; GIS Data</li>
                  </ul>
                </p>
              </p>
            </div>
            <div className="feature-basket-btn">
              <Button
                newPage={true}
                target="/learn-more"
                content="Learn More"
                style="main"
              />
            </div>
            <div className="feature-basket-img">
              <img
                src="https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_107_ofdufa.svg"
                alt=""
              />
            </div>
          </Col>
          <Col md={6} className="feature-img">
            <img
              src="https://res.cloudinary.com/emacon-production/image/upload/v1637569267/Bizmkononi/Work_7_aw53ps.svg"
              alt=""
            />
          </Col>
        </Row>
      </div>

      <div className="section-9">
        <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>Our Team</h1>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Row>
            {teamData.map((member, index) => (
              <Col
                md={3}
                sm={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  key={index}
                  renderBody={renderOurTeam(member)}
                  color="white"
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className="section-8">
        <Row>
          <Col md={3} className="footer">
            <h6>CONTACT US:</h6>
            <div>
              <p>+254 701 063018</p>
              <p>hello@mkononi.biz</p>
              <p>Nairobi, Kenya</p>
            </div>
          </Col>
          <Col md={3} className="footer">
            <h6>SERVICES:</h6>
            <div>
              <p>Contact Us</p>
              <p>Ordering & Payment</p>
              <p>FAQs</p>
            </div>
          </Col>
          <Col md={3} className="footer">
            <h6>INFORMATION:</h6>
            <div className="links-footer">
              <a href="/">Work With US</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/">Terms & Conditions</a>
              <a href="/">Press Enquiries</a>
            </div>
          </Col>
          <Col md={3} className="socials-footer">
            {socials.map((item, index) => (
              <div key={index} className="socials">
                <a href={item.to} target="_blank">
                  <i class={`bx bxl-${item.icon}`}></i>
                </a>
              </div>
            ))}
          </Col>
        </Row>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
