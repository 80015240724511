import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../components/card/Card";
import Copyright from "../components/copyright/Copyright";
import Navigation from "../components/Navigation/Navigation";
import { GApageView } from "..";

const socials = [
  {
    icon: "instagram-alt",
    to: "https://www.instagram.com/biz_mkononi",
  },
  {
    icon: "facebook",
    to: "https://www.facebook.com/MKONONIBIZKENYA",
  },
  {
    icon: "twitter",
    to: "https://twitter.com/BizMkononi",
  },
];

const uniqunessData = [
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569260/Bizmkononi/Group_110_sgnfpg.svg",
    title: "Stock management",
    Details:
      "This involves adding stock, editing stock and deleting stock. It also helps keeping account for the suppliers’ payment e.g. balances. This help in managing and tracking of business stock, the intake of stock and selling of stock. The data is stored in cloud, no data loss if a phone is stolen or data clearing for the phone.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/expenses_h4qtsv.svg",
    title: "Sales management",
    Details:
      "This involves adding sales, deleting and updating sales. Sales module provides a receipt for the sale made that can be shared digitally. This help in tracking f how business is doing in terms of sales. Sale can be filtered using date or a product or a customer. The data is stored in cloud, no data loss if a phone is stolen or data clearing for the phone.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Group_109_nxxzqf.svg",
    title: "Profit tracking",
    Details:
      "This helps in tracking the business profit in daily, weekly, monthly and yearly basis. The profit is displayed in the dashboard for quick access and by use of a user pin.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569264/Bizmkononi/sales_icon_ozqt3u.svg",
    title: "Expenses tracking",
    Details:
      "This involves tracking of business expenses, whether a sale expense, stock expenses, bus fare, meals, salaries etc. The expenses are calculated in real time and the profit is updated in real time.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569261/Bizmkononi/management_j4a9e6.svg",
    title: "Employees management",
    Details:
      "This involves management of a business employees. Employee salary, expenses and sales. Each employee has an account linked to the business. With each sale, the owner is notified. On Employee management, we will have a “Trello app’ kind of approach where we can assign duties and know how far one have gone.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569261/Bizmkononi/management_j4a9e6.svg",
    title: "Customer management",
    Details:
      "This involves management of business customer by tracking how they buy from the business, amount they owe the business etc. Send Sms or email to all customers at once",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569260/Bizmkononi/Group_110_sgnfpg.svg",
    title: "Suppliers management",
    Details:
      "This involves managing the suppliers of the business by tracking the stock they are supplying to the business, the amount the business owes to the suppliers etc.",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569260/Bizmkononi/Group_110_sgnfpg.svg",
    title: "Debt Management",
    Details: "Who owes and who do you owe",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/Fill_out_line_wikaq6.svg",
    title: "Analytics and graphical Record keeping",
    Details: "",
  },
  {
    image:
      "https://res.cloudinary.com/emacon-production/image/upload/v1637569258/Bizmkononi/expenses_h4qtsv.svg",
    title: "Handle payments",
    Details: "e.g. Bank transfers",
  },
];

const renderUniqueData = (image, title, details) => (
  <React.Fragment>
    <div className="card-img">
      <img src={image} alt="" />
    </div>
    <div className="card-title">
      <h3>{title}</h3>
    </div>

    <div className="card-description">
      <p>{details}</p>
    </div>
  </React.Fragment>
);

function LearnMore() {
  useEffect(() => {
    window.scrollTo(0, 0);
    GApageView("learn more");
  }, []);

  return (
    <div>
      <div className="section-1L">
        <div className="navigation">
          <Link to="/" className="company-logo">
            <img src="https://res.cloudinary.com/emacon-production/image/upload/v1638545506/Bizmkononi/BIZ_MKONONI_png_couxsj.png" />
          </Link>

          <div className="">
            <Navigation />
          </div>
        </div>
      </div>

      <div className="section-2L">
        <h2>TASKS PERFOMED BY BIZ MKONONI</h2>
        <p>
          The Biz mkononi platform aggregates, normalizes and models complex
          data to illuminate <br /> the ways which small and medium enterprises
          in Africa can be more resilient and robust through harnessing the
          power of technology.
        </p>
        <div className="uniquness-cards">
          <Row>
            {uniqunessData.map((item, index) => (
              <Col md={4} sm={12} key={index} className="uniquness-card">
                <Card
                  renderBody={renderUniqueData(
                    item.image,
                    item.title,
                    item.Details
                  )}
                  color="white"
                  borderRadius="topCorner"
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className="section-8">
        <Row>
          <Col md={3} sm={12} className="footer">
            <h6>CONTACT US:</h6>
            <div>
              <p>+254 701 063018</p>
              <p>hello@mkononi.biz</p>
              <p>Nairobi, Kenya</p>
            </div>
          </Col>
          <Col md={3} sm={12} className="footer">
            <h6>SERVICES:</h6>
            <div>
              <p>Contact Us</p>
              <p>Ordering & Payment</p>
              <p>FAQs</p>
            </div>
          </Col>
          <Col md={3} sm={12} className="footer">
            <h6>INFORMATION:</h6>
            <div>
              <p>Work With US</p>
              <p>Privacy Policy</p>
              <p>Terms & Conditions</p>
              <p>Press Enquiries</p>
            </div>
          </Col>
          <Col md={3} sm={12} className="socials-footer">
            {socials.map((item, index) => (
              <div key={index} className="socials">
                <a href={item.to} target="_blank">
                  <i class={`bx bxl-${item.icon}`}></i>
                </a>
              </div>
            ))}
          </Col>
        </Row>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
